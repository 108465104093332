/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import styled from 'styled-components';
import { textMedium } from '@exo/frontend-common-style-utils';
import { ContentWrapper } from '../ContentWrapper/ContentWrapper';
import theme from './NotAllowed.theme';

export const PageWrapper = styled(ContentWrapper)`
  height: 5rem;
  width: 28rem;
  margin: 5rem auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled('div')`
  color: ${props => theme(props).errorRed};
  width: 100%;
  margin: 0.5rem 0;
  ${textMedium}
`;

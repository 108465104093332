/* eslint-disable no-restricted-syntax */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { useIntl } from '@exo/frontend-common-i18n';
import { UserAdmin24, Touch_124, Password24 } from '@carbon/icons-react/es';
import * as S from './WelcomePage.styles';
import { WelcomeMenuItem } from '../../components/WelcomeMenuItem/WelcomeMenuItem';
import { ApplicationConfig } from '@exo/frontend-common-app-shell';
import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';
import { useHistory } from 'react-router-dom';

export const WelcomePage = ({ config }: Props) => {
  const intl = useIntl('features.home.home-ui.pages.WelcomePage.content');
  const session = usePolarisSessionContext();
  const history = useHistory();
  /* c8 ignore next 3 */
  if (session?.roles?.length > 0) {
    history.push('/my-home');
  }
  const items = [
    {
      icon: <UserAdmin24></UserAdmin24>,
      label: intl.msg('registered_user', 'Registered User?'),
      actionLabel: ['Login'],
      actionLink: ['/account/login']
    },
    {
      icon: <Touch_124></Touch_124>,
      label: intl.msg('new_user', 'New User?'),
      actionLabel: ['Register'],
      actionLink: ['/register/register-intro']
    },
    {
      icon: <Password24></Password24>,
      label: intl.msg('forgotten_password', 'Forgotten password?'),
      actionLabel: ['Reset', ' or ', 'enter code'],
      actionLink: [
        /* c8 ignore next 3 */
        `${config?.featureConfig?.endpoints?.oauthLink}/auth/reset-password`,
        '',
        `${config?.featureConfig?.endpoints?.oauthLink}/auth/enter-code`
      ]
    }
  ];
  /* c8 ignore next 2 */
  const headerLinks = config?.featureConfig?.chrome?.header.fixedLinks;
  const aboutUsLink = headerLinks?.find(l => l.label.toLowerCase().includes('about'));
  return (
    <S.WelcomePageContainer>
      <>
        <h5>{intl.msg('title', 'Welcome to imarket')}</h5>
      </>
      <>
        <p>{intl.msg('subtitle', 'Bringing intermediaries and insurers together')}</p>
      </>
      <S.WelcomePageIntro>
        <S.WelcomePageIntroContent>
          <p>
            {intl.msg(
              'intro.p1',
              `imarket is the solution that connects Brokers to insurers to digitally trade business.
            It is run by Polaris UK Ltd on behalf of the UK insurance market.`
            )}
          </p>
          <p>
            {intl.msg(
              'intro.p2',
              `This is the Administrator Portal that allows Brokers and insurers to classify and
            arrange their imarket services as well as access additional Products & Services.`
            )}
          </p>
          <p>
            {
              intl.msg(
                'intro.p3',
                'For more information on imarket please visit the <hresf>{link}</hresf>',
                {
                  /* c8 ignore next 6 */
                  link: aboutUsLink?.href as string,
                  hresf: str => (
                    <a href={str} target="_blank">
                      {intl.msg('about', 'About imarket page')}
                    </a>
                  )
                }
              ) as string
            }
          </p>
          <p>
            {intl.msg(
              'intro.p4',
              `If you are a Broker please refer to your software house if you are registering in order
            to gain access to commercial digital services from an imarket insurer.`
            )}
          </p>
        </S.WelcomePageIntroContent>
        <S.WelcomePageIntroMenu>
           {/* c8 ignore next */}
          {items?.map(item => (
            <WelcomeMenuItem key={'welcome-page-' + item.label} item={item}></WelcomeMenuItem>
          ))}
        </S.WelcomePageIntroMenu>
      </S.WelcomePageIntro>
    </S.WelcomePageContainer>
  );
};

type Props = {
  config: ApplicationConfig;
};

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import styled from 'styled-components';
import theme from './UnauthenticatedPage.theme';

export const PageWrapper = styled('div')`
    display: flex;
    justify-content: center;
    padding: 4rem;
`;

export const Container = styled('div')`
    background-color: ${props => theme(props).containerColor};
    max-height: 7rem;
    padding-top: 2.06rem;
    width: 31.5rem;

    .last{
        padding-bottom: 1.75rem;
    }
`;

export const Text = styled('p')`
    font-size: 0.875rem;
    padding-bottom: 0.81rem;
    padding-left: 2.06rem;
`
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './GatewayTimeout.styles';
import { useIntl } from '@exo/frontend-common-i18n';

export const GatewayTimeout = () => {
  const intl = useIntl('features.insurance.gatewayTimeout');

  return (
    <S.GatewayTimeout>
      <S.Container>
        <S.Text>{intl.msg('text', 'Something went wrong.') as string}</S.Text>
      </S.Container>
    </S.GatewayTimeout>
  );
};

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import * as CSS from 'csstype';

type Props = {
  containerColor?: CSS.Property.Color;
  colorPurple?: CSS.Property.Color;
};

declare global {
  interface EXOComponentStyles {
    insurance_gatewayTimeout?: Partial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return {
    containerColor: props.theme.colors.backgrounds.panels.secondary.base,
    colorPurple: props.theme.colors.text.purple,
    ...props.theme?.byComponent?.insurance_gatewayTimeout
  };
};

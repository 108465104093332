/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled from 'styled-components';

export const MenuItem = styled('div')`
align-items: center;
column-gap: 0.625rem;
display: grid;
grid-template-areas: "icon label"
                     "icon action";
grid-template-columns: 1.5rem 10.063rem;
grid-template-rows: auto;
height: 2.188rem;
justify-items: start;
margin-bottom: 2.725rem;

    & svg {
        fill: #71509b;
    }
`

export const Icon = styled('div')`
grid-area: icon;
`

export const Label = styled('div')`
font-size: 0.875rem;
grid-area: label;
`

export const Span = styled.span`
font-size: 0.875rem;
`

export const Action = styled('div')`
grid-area: action;
/* stylelint-disable-next-line selector-max-type */
  & a {
      text-decoration: underline;
  }
`
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import { media } from '@exo/frontend-common-style-utils';
import theme from './WelcomePage.theme'

export const WelcomePageContainer = styled('div')`
/* stylelint-disable selector-max-type */
    align-items: center;
    color: ${props => theme(props).contentColor};
    height: 28.125rem;
    justify-content: center;
    margin-top: 3.4375rem;
    width: 57rem;

    & p {
        font-size: 0.75rem;
        font-weight: 500;
    }

    & a {
        color: ${props => theme(props).linkColor};
        font-size: 0.875rem;
        font-weight: 400;
        text-decoration: underline;
    }
    ${props => media.lessThan(props, 'large').then(css`
       margin-top: 2rem; 
    `)}
`

export const WelcomePageIntro = styled('div')`
    background-color: ${props => theme(props).pageBgColor};
    color: ${props => theme(props).defaultFontColor};
    display: flex;
    flex-direction: row;
    height: 16.5rem;
    justify-content: space-between;
    margin-top: 3.25rem;
    padding: 2rem 2rem;
    width: 100%;

    ${props => media.lessThan(props, 'large').then(css`
       margin-top: 2.25rem;
    `)}
`
export const WelcomePageIntroContent = styled('div')`
    width: 38.75rem;

    & p {
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.01rem;
        line-height: 1.5rem;
        margin-bottom: 0.725rem;
    }
`

export const WelcomePageIntroMenu = styled('div')`
    width: 12rem;
`

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import * as CSS from 'csstype';
import { rgba } from 'color2k';

type Props = {
  pageBgColor: string;
  contentBgColor: string;
  contentPadding: string;
  contentColor: string;
  loadingBgColor: CSS.Property.BackgroundColor;
  linkColor: string;
  defaultFontColor?: string;
};

declare global {
  interface EXOComponentStyles {
    chrome_ui_chrome?: Partial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return {
    pageBgColor: '#fff',
    contentBgColor: props.theme.colors.backgrounds.page,
    // Content spacing is applied to the Main content, and controls space between header, main, and footer content
    contentPadding: '0',
    contentColor: '#71509b',
    loadingBgColor: rgba(244, 244, 244, 0.6),
    linkColor: '#71509b',
    defaultFontColor: '#000',

    ...props.theme?.core?.chrome,
    ...props.theme?.byComponent?.chrome_ui_chrome
  };
};

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import styled, { css } from 'styled-components';
import { media } from '@exo/frontend-common-style-utils';
import theme from './GatewayTimeout.theme';

export const GatewayTimeout = styled('div')`
  display: flex;
  justify-content: center;

  ${props => media.greaterThan(props, 'medium').then(css`
   padding: 4rem;
  `)}

  ${props => media.greaterThan(props, 'small').then(css`
    padding: 4rem;
  `)}
`;

export const Container = styled('div')`
  align-items: center;  
  background-color: ${props => theme(props).containerColor};
  display: flex;
  justify-content: center;
  max-height: 7rem;
  width: 31.5rem;

  .last{
    padding-bottom: 1.75rem;
  }
`;

export const Text = styled('p')`
  font-size: 0.875rem;
`


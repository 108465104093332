/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/* eslint-disable no-restricted-syntax */

import React, { useEffect } from 'react';
import { useIntl } from '@exo/frontend-common-i18n';
import * as S from './UnauthenticatedPage.styles';
import { Link as ReactLink } from 'react-router-dom';
import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';

export const UnauthenticatedPage = () => {
  const intl = useIntl('features.home.home-ui.pages.UnauthenticatedPage');
  const sessionStorage = usePolarisSessionContext();

  useEffect(() => {
    sessionStorage.replace({
      type: 'NONE',
      roles: []
    } as EXOSession);
  }, []);

  return (
    <S.PageWrapper>
      <S.Container>
        <S.Text className="error">
          {intl.msg('text1', 'your session has expired.') as string}
        </S.Text>
        <S.Text>
          {
            intl.msg('text2', 'please <a>log in</a> again.', {
              a: str => <ReactLink to={{ pathname: '/account/login' }}>{str}</ReactLink>
            }) as string
          }
        </S.Text>
      </S.Container>
    </S.PageWrapper>
  );
};

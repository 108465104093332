/* eslint-disable no-restricted-syntax */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './WelcomeMenuItem.styles';

export const WelcomeMenuItem = ({ item }: Props) => {
  return (
    <S.MenuItem>
      <S.Icon>{item.icon}</S.Icon>
      <S.Label>{item.label}</S.Label>
      <S.Action>
        {item.actionLabel.map((i, index) => {
          return item.actionLink[index] === '' ? (
            <S.Span key={'welcome-item-' + i}>{i}</S.Span>
          ) : (
            <a
              key={'welcome-item-' + i}
              data-cy={i.split(' ')?.join('-')}
              href={item.actionLink[index]}
            >
              {i}
            </a>
          );
        })}
      </S.Action>
    </S.MenuItem>
  );
};

type Props = {
  item: {
    icon: JSX.Element | string;
    label: string | React.ReactNode;
    actionLink: string[];
    actionLabel: string[];
  };
};

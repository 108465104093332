/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import * as CSS from 'csstype';

type Props = {
  errorRed: CSS.Property.Color;
};

declare global {
  interface EXOComponentStyles {
    notAllowedPage?: Partial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return {
    errorRed: '#da1e28',
    ...props.theme?.byComponent?.notAllowedPage
  };
};

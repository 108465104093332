/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './NotAllowed.styles';
import { useIntl } from '@exo/frontend-common-i18n';
import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';
import { Link as ReactLink } from 'react-router-dom';

export const NotAllowed = () => {
  const intl = useIntl('features.insurance.notAllowed');
  const session = usePolarisSessionContext();
  const { blockedUser } = session;

  const msg = blockedUser
    ? intl.msg(
        'text2',
        'You are not allowed. Please set a <hresf>security question</hresf> first.',
        {
          hresf: str => (
            <ReactLink to={{ pathname: '/account/update-security-info' }}>{str}</ReactLink>
          )
        }
      )
    : intl.msg('text1', 'Sorry, You are not allowed to access this page.');
  return (
    <S.PageWrapper>
      <S.Content>{msg}</S.Content>
    </S.PageWrapper>
  );
};

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { Route } from 'react-router-dom';
import React from 'react';
import { ApplicationConfig, AppShellSwitch } from '@exo/frontend-common-app-shell';
import { PageNotFound } from '@exo/frontend-features-insurance-chrome-ui';
import { WelcomePage } from './pages/WelcomePage/WelcomePage';
import { NotFound } from './pages/NotFound/NotFound';
import { NotAllowedPage } from './pages/NotAllowedPage/NotAllowedPage';
import { UnauthenticatedPage } from './pages/UnauthenticatedPage/UnauthenticatedPage';
import { GatewayTimeout } from './pages/GatewayTimeout/GatewayTimeout';

const Routes = ({ config }: { config: ApplicationConfig }) => {
  return (
    <AppShellSwitch prefix="/home" missing={PageNotFound}>
      <Route path="/home/welcome" render={() => <WelcomePage config={config} />} />
      <Route path="/home/register" component={WelcomePage} />
      <Route path="/home/about" component={WelcomePage} />
      <Route path="/home/not-found" component={NotFound} />
      <Route path="/home/not-allowed" component={NotAllowedPage} />
      <Route path="/home/unauthenticated" component={UnauthenticatedPage} />
      <Route path="/home/timeout" component={GatewayTimeout} />
    </AppShellSwitch>
  );
};

export default Routes;
